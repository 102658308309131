<template>
	<v-row>
		<v-col
			cols="12"
			sm="9"
		>
			<v-sheet
				rounded="lg"
				outlined
			>
				<v-card>
					<v-card-title>
						What The Duck?
					</v-card-title>
					<v-card-subtitle>An interactive Ecosystem of projects with a common theme</v-card-subtitle>
				</v-card>		
				

			</v-sheet>
			<v-spacer class="my-5"></v-spacer>
		</v-col>

		<v-col
			cols="12"
			sm="3"
		>
			<v-sheet
				rounded="lg"
				min-height="268"
			>

				<!--team-->
			</v-sheet>
		</v-col>
	</v-row>
</template>
<script>
export default {
	data() {
		return {
			publicPath: process.env.BASE_URL,
			slides: [1, 2, 3, 4, 5],
			attrs: {
				class: "mb-1",
				boilerplate: false,
				light: true,
				elevation: 2,
			},
			homeTags: ["homepage"],
			featureListener: false,
			features:[]
		};
	},
	components: {
	},
	mounted(){
	},
	beforeDestroy(){
	}
};
</script> 